<template>
  <v-card class="problem-card"
          style="z-index: 2; position: relative !important; min-height: 400px"
          :key="'problem_image_' + (problem ?  problem.problem_id : '') + '_' + type"
  >

    <!------------------------------->
    <!--- Feedback topic problem ---->
    <!------------------------------->
    <feedback-topic-problem :problem="problem"></feedback-topic-problem>

    <!------------------------------->
    <!--- Problem                ---->
    <!------------------------------->
    <div
      class="pl-8 pt-7 d-flex flex-column">
      <span
        v-if="problem"
        class="font-rubik font-weight-bold"
        style="font-size: 16px; color: rgb(46, 56, 86)"
      >
        {{ enunciat ? 'Enunciat' : 'Resultat' }}
      </span>
    </div>


    <div v-if="!imageExists" class="text-center my-auto align-center d-flex flex-column pt-12" style="flex: 1">
      <span class="font-rubik py-4">
        Aquest problema no té solució oficial de la Generalitat de Catalunya. <br>
        Si vols, ens pots enviar per WhatsApp la teva solució per comparar-la amb la dels altres estudiants.
      </span>
      <v-img src="https://examenselectivitat.cat:3000/api/images/other/sele-whatsapp.webp"
             style="max-width: 190px"
             class="mx-auto"
      ></v-img>
    </div>


    <div v-if="problem && enunciat && problem.audio && imageExists" class="d-flex py-5">
      <audio controls class="mx-auto">
        <source
          :src="`https://examenselectivitat.cat:3000/api/audio/${problem.subject_path}/${problem.year}/${problem.serie + (problem.audio_name ? problem.audio_name : '')}`"
          type="audio/mpeg">
        El teu navegador no soporta el reproductor d'audio.
      </audio>
    </div>

    <!--    <div-->
    <!--      v-if="loadingImage"-->
    <!--      type="image"-->
    <!--    ></div>-->


    <div
      v-if="imageExists"
      style="height: fit-content; position: relative" class="my-auto">


      <v-img
        @load="handleLoad"
        :src="getImage()"
        height="100%"
        width="100%"
        class="rounded-lg py-8"
        style="width: 100%; height: 100%; border-radius: 0.5rem; z-index: 2"
        contain
      ></v-img>


      <div
        style="position: absolute;  top: 0; left: 0; z-index: 10; width: 100%; height: 100%"
      >
<!--        <svg-->
<!--          width="100%"-->
<!--          height="100%"-->
<!--          viewBox="0 0 500 75"-->
<!--          preserveAspectRatio="xMinYMid meet"-->
<!--          xmlns="http://www.w3.org/2000/svg"-->
<!--          xmlns:xlink="http://www.w3.org/1999/xlink"-->
<!--        >-->
<!--          <text-->
<!--            font-size="42"-->
<!--            fill="rgba(20, 102, 199, 0.15)"-->
<!--            class="font-rubik font-weight-black"-->
<!--            dominant-baseline="middle" text-anchor="middle"-->
<!--            style="letter-spacing: -3px"-->
<!--            :transform="'translate(0,70) rotate(' + -15 + ')'"-->
<!--            x="50%"-->
<!--            y="50%"-->
<!--          >-->
<!--            examenselectivitat.cat-->
<!--          </text>-->
<!--        </svg>-->
      </div>

    </div>
  </v-card>
</template>

<script>

import FeedbackTopicProblem from "./feedback/feedback-topic-problem.vue";

export default {
  name: "ProblemImage",
  components: {FeedbackTopicProblem},
  props: {
    problem: {
      required: true
    },
    enunciat: {
      required: true,
      default: true
    }
  },
  async mounted() {
    this.imageExists = await this.doesImageExists()
  },
  data() {
    return {
      url: process.env.VUE_APP_AXIOS_URI,
      loadingImage: false,
      imageExists: true,
    }
  },
  computed: {
    type() {
      return this.enunciat ? 'problem' : 'solution'
    }
  },
  methods: {
    getImage() {
      return `https://examenselectivitat.cat:3000/api/images/${this.problem.subject_path}/${this.problem.year}/${this.problem.serie}/${this.problem.problem_id}/${this.type}`
    },
    handleLoad() {
      this.loadingImage = true;
    },
    async doesImageExists() {
      try {
        const options = {
          method: "GET"
        }
        const response = await fetch(this.getImage(), options);
        return response.status === 200

        // this.imageExists=true
      } catch (e) {
        console.error("fetch", e)
        return false
      }
    }
  }
}
</script>

<style>
.problem-card {
  border-radius: 0.5rem !important;
  box-shadow: 0 .25rem 1rem 0 rgba(48, 53, 69, .08) !important;
}

</style>
