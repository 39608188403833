<template>
  <div class="d-flex flex-column">
    <v-hover v-slot:default="{ hover }" open-delay="50">
      <v-icon
        @click="topicFeedback=!topicFeedback"
        style="position: absolute; right: 16px; top: 16px; z-index: 2; cursor: pointer;"
        :color="hover ? 'amber' : '#2E3856'"
      >
        {{ mdiAlert }}
      </v-icon>
    </v-hover>

    <v-dialog v-model="topicFeedback"
              max-width="800"
              :key="'topic_feeback_' + problem.problem_id + '_' + refresh"
    >
      <v-card class="pa-10">
        <span
          class="font-rubik font-weight-bold"
          style="font-size: 17px; color: rgb(46, 56, 86)"
        >
          Aquest exercici conté un error?
        </span>
        <br>
        <span
          class="font-rubik font-weight-regular"
        >
          Indica'ns el tipus d'error i el canviarem tan de pressa com sigui possible!
        </span>

        <v-stepper v-model="stepperFeedbackModel" flat class="pt-5">

          <v-stepper-items>
            <v-stepper-content step="1" class="pa-0">
              <v-card
                class="d-flex flex-column"
                flat
              >
                <div class="d-flex flex-column mb-4" style="flex: 1">
                  <v-card
                    v-for="(error, idxE) in errors"
                    @click="selectedError=idxE, stepperFeedbackModel = 2"
                    class="rounded-lg d-flex align-center px-4 mb-3"
                    outlined
                    height="50"
                  >
                  <span class="font-rubik" style="color: #39445b; flex: 1">
                    {{ error }}
                  </span>
                    <v-icon style="border: thin solid #d5d5db" class="rounded-circle">{{ mdiChevronRight }}</v-icon>
                  </v-card>
                </div>

              </v-card>

            </v-stepper-content>

            <v-stepper-content step="2" class="pa-0">
              <v-card
                class="mb-12"
                flat
              >

                <v-alert color="warninhide_fileg" text dense border="left">
                  <span class="font-weight-bold">{{ errors[selectedError] }}</span>
                </v-alert>

                <v-textarea v-model="informacioExtra"
                            label="Informació extra sobre l'error (opcional)"
                            solo flat
                            style="border: thin solid #E0E0E0"
                            hide-details
                ></v-textarea>

              </v-card>


              <div class="d-flex">
                <v-btn
                  @click="stepperFeedbackModel=1"
                  text class="text-none pl-0 pr-2 font-rubik">
                  <v-icon>
                    {{ mdiChevronLeft }}
                  </v-icon>
                  Tornar
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                  @click="stepperFeedbackModel=3"
                  class="text-none font-rubik px-5"
                  color="primary">
                  Continuar
                </v-btn>

              </div>
            </v-stepper-content>


            <v-stepper-content step="3" class="pa-0">
              <v-card
                class="mb-12"
                flat
              >

                <v-alert color="warning" text dense border="left">
                  <span class="font-weight-bold">{{ errors[selectedError] }}</span>
                </v-alert>

                <v-radio-group v-model="selectedType"
                               :column="false"
                >
                  <v-radio
                    v-for="(type, n) in typesOfPeople"
                    :key="n + '-type-of-people'"
                    style="z-index: 2"
                    :label="type"
                    class="mr-7"
                    :value="n"
                  ></v-radio>
                </v-radio-group>
              </v-card>


              <div class="d-flex">
                <v-btn
                  @click="stepperFeedbackModel=2"
                  text class="text-none pl-0 pr-2 font-rubik">
                  <v-icon>
                    {{ mdiChevronLeft }}
                  </v-icon>
                  Tornar
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                  @click="stepperFeedbackModel=4"
                  class="text-none font-rubik px-5"
                  color="primary">
                  Continuar
                </v-btn>

              </div>
            </v-stepper-content>


            <v-stepper-content step="4" class="pa-0">
              <v-card
                class="mb-12"
                flat
              >

                <v-alert color="warning" text dense border="left">
                  <span class="font-weight-bold">{{ errors[selectedError] }}</span>
                </v-alert>

                <span class="font-rubik font-weight-regular">Correu electrònic</span>

                <v-text-field v-model="email"
                              type="email"
                              label="Correu"
                              :autofocus="true"
                              solo flat
                              hide-details="true"
                              style="border: thin solid #E0E0E0"
                              class="my-2"
                ></v-text-field>
              </v-card>


              <div class="d-flex">
                <v-btn
                  @click="stepperFeedbackModel=3"
                  text class="text-none pl-0 pr-2 font-rubik">
                  <v-icon>
                    {{ mdiChevronLeft }}
                  </v-icon>
                  Tornar
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                  :disabled="email.length === 0 || !email.includes('@')"
                  @click="sendFeedback"
                  class="text-none font-rubik px-5"
                  color="primary">
                  Enviar!
                </v-btn>
              </div>
            </v-stepper-content>

          </v-stepper-items>
        </v-stepper>

      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {mdiAlert, mdiChevronRight, mdiChevronLeft} from '@/assets/mdi.json';
import Vue from "vue";

export default {
  name: "FeedbackTopicProblem",
  props: {
    problem: {
      required: true
    },
  },
  mounted() {
    const feedbackInfo = Vue.$cookies.get("feedback_info");
    if (feedbackInfo) {
      this.selectedType = feedbackInfo["type_of_person"],
      this.email = feedbackInfo["email"]
    }
  },
  data() {
    return {
      refresh: 0,
      topicFeedback: false,
      selectedType: 0,
      selectedError: null,
      stepperFeedbackModel: 1,
      mdiChevronLeft: mdiChevronLeft,
      mdiChevronRight: mdiChevronRight,
      mdiAlert: mdiAlert,
      errors: [
        "Està incomplet",
        "No està ben classificat per temes",
        "No es veu bé",
      ],
      informacioExtra: "",
      email: "",
      typesOfPeople: [
        "Estudiant",
        "Professor particular",
        "Professor"
      ],
      emailHasError: false

    }
  },
  methods: {
    updateError(val) {
      console.log(val)
    },
    async sendFeedback() {
      Vue.$cookies.set("feedback_info", {
        "email": this.email,
        "type_of_person": this.selectedType
      })

      const obj = {
        "email": this.email,
        "type_of_person": this.typesOfPeople[this.selectedType],
        "error_text": this.errors[this.selectedError],
        "problem_id": this.problem.problem_id,
        "informacio_extra": this.informacioExtra,
      }


      this.topicFeedback = false;
      this.refresh++;
      this.stepperFeedbackModel = 1

      await this.axios.post("/upload-problem-topics-feedback", obj)
      // this.selectedError = null
      // this.informacioExtra = ""
    }
  }
}
</script>

<style scoped>

</style>
